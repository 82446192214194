<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 60px">
				<div>
					<h3 class="card-title align-items-start flex-column">
						<span class="card-label font-weight-bolder"> CRC Errors </span>
					</h3>
				</div>
			</div>
			<ITCSpinner :loaded="loaded">
				<div class="card-body pt-2 pb-0">
					<div class="row" v-if="showNetwork">
						<div class="col-md-3">
							<h2>{{ showNetwork }}</h2>
						</div>
						<div class="col-md-6"></div>
						<div class="col-md-3">
							<a @click="backToNetworks">
								<span class="symbol-label">
									<inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg" />
								</span>
								Back to Network List</a
							>
						</div>
					</div>
					<div v-if="data" style="max-height: 300px; overflow-y: scroll">
						<b-table
							:fields="fields"
							:items="showNetwork ? netdata : data"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							sort-icon-left
						>
							<template #cell(name)="data">
								<a
									v-if="!showNetwork"
									class="text-primary text-underline"
									@click="netClicked(data.item.server, data.item.NetworkId)"
									>{{ data.item.name }}</a
								>
								<a
									v-if="showNetwork"
									class="text-primary text-underline"
									@click="modemClicked(data.item.SiteId)"
									>{{ data.item.name }}</a
								>
							</template>
							<template #cell(lastdown)="data">
								{{ data.item.lastdown }}
							</template>
							<template #cell(downcrc)="data">
								<SparkLine
									width="300"
									:tooltipProps="sparkTooltip"
									:tooltipDates="data.item.opts.tooltipValueLookups.names"
								>
									<sparklineLine
										:data="data.item.downcrc"
										:limit="data.item.downcrc.length"
										:styles="spLineStyles1"
										:refLineType="false"
									/>
								</SparkLine>
							</template>
							<template #cell(lastup)="data">
								{{ data.item.lastup }}
							</template>
							<template #cell(upcrc)="data">
								<SparkLine
									width="300"
									:tooltipProps="sparkTooltip"
									:tooltipDates="data.item.opts.tooltipValueLookups.names"
								>
									<sparklineLine
										:data="data.item.upcrc"
										:limit="data.item.downcrc.length"
										:styles="spLineStyles1"
										:refLineType="false"
									/>
								</SparkLine>
							</template>
						</b-table>
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import SparkLine from 'vue-sparklines';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import router from '@/router';

export default {
	components: { SparkLine, ITCSpinner },
	mixins: [swMixin],
	name: '',
	data() {
		return {
			data: [],
			netdata: [],
			loaded: false,
			spIndicatorStyles1: false,
			spLineStyles1: {
				stroke: '#54a5ff',
				fill: '#084875',
			},
			sparkTooltip: {
				formatter(v) {
					return v.date + ' (' + v.value + ')';
				},
			},

			fields: [
				{ key: 'Name', sortable: true, tdClass: 'namecol' },
				{
					key: 'lastdown',
					label: 'Last Downstream',
					sortable: true,
					sortDirection: 'desc',
					tdClass: 'lastcol',
				},
				{ key: 'downcrc', label: 'Downstream Graph', sortable: true, tdClass: 'sparkgraphcol' },
				{ key: 'lastup', label: 'Last Upstream', sortable: true, tdClass: 'lastcol' },
				{ key: 'upcrc', label: 'Upstream Graph', sortable: true, tdClass: 'sparkgraphcol' },
			],
			showNetwork: null,
			sortBy: 'lastdown',
			sortDesc: true,
		};
	},
	methods: {
		netClicked(server, network) {
			this.loaded = false;
			this.$http.get(`idirect/network/${server}/${network}/crc`).then(resp => {
				this.showNetwork = resp.data.data.name;
				this.netdata = resp.data.data.remotes;
				this.loaded = true;
			});
		},
		modemClicked(siteId) {
			router.push({ name: 'site', params: { id: siteId } });
		},
		backToNetworks() {
			this.showNetwork = null;
			this.loaded = false;
			this.$http.get('idirect/networks/crc').then(resp => {
				this.data = resp.data.data;
				this.loaded = true;
			});
		},
	},
	created() {
		this.loaded = false;
		this.$http.get('idirect/networks/crc').then(resp => {
			this.data = resp.data.data;
			this.loaded = true;
		});
		this.setSWListener('idirect/networks/crc', data => {
			this.data = data.data;
		});
	},
};
</script>

<style>
a {
	cursor: pointer;
}
.namecol {
	width: 20%;
}
.lastcol {
	width: 10%;
}
.sparkgraphcol {
	width: 30%;
}
</style>
